@import "../../../_global.scss";

.modalContainer {
    padding: 20px;
    width: 100%;
    max-width: 90%;
    height: 90%;

    /* max-height: 500px; */
    // max-height: 80vh;
    border-radius: 18px;

    /* background-color: #0D0D1C; */
    background-color: $mainBgColor;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1000;
    overflow: scroll;
}

hr {
    border-top: 1px solid #48556F !important;
    width: 90%;
}

input::-ms-reveal {
    filter: invert(100%);
}

.workoutImage {
    border-radius: 25px;
}

@media only screen and (max-width: 768px) {
    .modalContainer {
        max-width: 90vw;
    }
}
