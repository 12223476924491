@import "../../../../global";

.exerciseSection {
    width: 100%;
    padding: 10px;
    margin: 25px 0px;
    background-color: $mirage;
    border-radius: 20px;
    position: relative;
    color: white;

    .exerciseNumber {
        width: fit-content;
        font-weight: bold;
        background-color: $carrotOrange;
        color: white;
        padding: 6px 4px;
        border-radius: 6px 6px 0px 0px;
        position: absolute;
        right: 25px;
        top: -35px;
        z-index: -1;
    }
}