.EditProfile {
    width: 100%;
    height: 100%;
    overflow: auto;

    .form {
        margin: auto;
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .userImage {
            width: 110px;
            height: 110px;
            border-radius: 100%;
        }


    }
}