@import "../../../global";

.MainContainer {
    width: 100%;
    height: fit-content;
}

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.titleContainer {
    display: flex;
    justify-content: space-between;

    /* width: 100%; */
    align-items: center;
}

.mainTable {
    /* margin:20px */
    margin: 20px 0px;
}

.btnBg {
    // background: linear-gradient(180deg, #29A5DE 24.41%, #2B3C8E 94.01%) !important;

    background-color: $primaryColor;
}

.active {
    background-color: green;
    padding: 3px;
    border-radius: 18px;
}

.block {
    background-color: red;
    padding: 3px;
    border-radius: 18px;
}
