@import "../../../global";

.MainContainer {
    width: 100%;
    height: fit-content;
}

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
