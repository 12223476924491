@import "../../global";

.select {
    background-color: $mainBgColor;
    border-width: 1px;
    border-style: solid;
    border-color: $primaryColor;
    border-radius: 18px !important;
    color: white !important;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 5px 0px 15px 0px;
    width: 100%;

    // arrow working
    background-image: linear-gradient(50deg, transparent 50%, white 60%), linear-gradient(125deg, white 40%, transparent 50%) !important;
    background-position: calc(100% - 20px) 20px, calc(100% - 15px) 20px, 100% 0;
    background-size: 6px 10px, 6px 10px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
}

.select::placeholder {
    color: white !important;
}

.select:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem $primaryColorWithAlpha;
}

select {}
