@import "../../../global";

.ChangePassword {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color: $secondBgColor;

    .form,
    .saveButton {
        width: 100%;
        max-width: 400px;
    }

    .form {
        height: -webkit-fill-available;
        margin-right: auto;
        display: flex;
        flex-direction: column;
    }

    .saveButton {
        margin-top: auto;
    }
}
